import axios from 'axios';
import { FUNCTION_APP_ROOT, FUNCTION_APP_VALIDATE_TOKEN_CODE } from '../Constants';

export const validateToken = async (token: string): Promise<boolean> => {
        try {
                const requestUrl = `${FUNCTION_APP_ROOT}/api/validate/${token}?code=${FUNCTION_APP_VALIDATE_TOKEN_CODE}`;
                const result = await axios.get(requestUrl)
                .then((response: any): Promise<boolean> => {
                        return response.data;
                })
                .then((data: boolean) => {
                        return data;
                });
                return result;
        } catch (error: any) {
                console.error('Error validating token', error);
                return false;
        }
};
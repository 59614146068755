import axios from 'axios';
import { IFormInput } from '../interfaces/interface';
import { LOGIC_APP_ROOT } from '../Constants';
import { ApplicationStatus } from '../constants/constants';
import { registrationStrings as strings } from '../constants/texts';

export const submitForm = async (data: IFormInput): Promise<ApplicationStatus> => {
        try {
                const result = await axios.post(`${LOGIC_APP_ROOT}`, data);
                if (result.status === 200) {
                        return ApplicationStatus.APPROVED;
                } else {
                        return ApplicationStatus.FAILED;
                }
        } catch (error: any) {
                if (error?.response?.status === 400 && error?.response?.data?.message === strings.error.alreadyExists) {
                        return ApplicationStatus.ALREADYEXISTS;
                } else if (error?.response?.status === 400 && error?.response?.data?.message === strings.error.maximumNumberOfCategoriesExceeded) {
                        return ApplicationStatus.MAXIMUMCATEGORIES;
                }
                console.error('Error submitting form', error);
                return ApplicationStatus.FAILED;
        }
}

export const registrationStrings = {
    error: {
        category: "Please select a category",
        email: "Please enter a valid email address",
        name: "Please enter your name",
        phoneNumber: "Please enter a valid phone number",
        orgName: "Please enter your organization's name",
        alreadyExists: "mail + category combination already exists",
        maximumNumberOfCategoriesExceeded: "maximumNumberOfCategoriesExceeded",
    },
    categories: {
        category1: "Forwards funds to other civil society actors",
        category2: "Does not forward funds to other civil society actors",
        category3: "Forwards funds to civil society actors in ODA countries",
        category4: "Operations are focused on the strategy objective \"an enabling environment for civil society in developing countries\" and does not forward funds to civil society actors in ODA countries",
        category5: "Operations are focused on the strategy objective \"an enabling environment for civil society in developing countries\" and forwards funds to civil society actors in ODA countries",
    },
    applicationStatus: {
        approvedHeader: "Thank you for registering on the portal to express interest for partnership with Sida.",
        approvedSubHeader: "A link has been sent to the email address you have provided, which can be used to access the expression of interest form. Neither Sida nor its service provider can be held responsible for any inaccuracies or discrepancies in your expression of interest resulting from sharing the link with third parties",
        approvedDecription: "Unless explicitly stated as otherwise, the material and information in the form is intended for clarification and informative purposes only and does not constitute advice or recommendations.",
        approvedDescription2: "Unless explicitly stated otherwise, the information provided by this form should be regarded solely as guidance and not as definitive instructions for what you should write.",
        alreadyExistsHeader: "We regret to inform you that your registration on the portal to express interest for partnership with Sida could not be completed successfully due to the email address having already been registered in our system twice.",
        alreadeExistsSubheader: "Please ensure that you use a unique email address, or reach out to Sida's service provider for further clarification.",
        maximumApplicationsHeader: "We regret to inform you that your registration on the portal to express interest for partnership with Sida could not be completed successfully.",
        maximumApplicationsSubheader: "The maximum number of registrations through the email address provided has already been reached. Please use a different email address if you represent another organisation or contact Sida for further assistance if you believe an error has occurred.",
        failedHeader: "We regret to inform you that your registration on the portal could not be completed successfully due to an unspecified error.",
        failedSubHeader: "Please verify your information and try again, or contact Sida's service provider for further assistance.",
    },
    global: {
        submitButton: "Submit",
        goBackButton: "Go back to registration",
        registrationHeader: "Invitation to CSOs for Partnership with Sida",
        registrationSubHeader: "Once you have submitted the information requested in the registration form, you will receive a unique link to the email address you have provided. Use this link to access and complete the expression of interest form. Your progress will be saved automatically, allowing you to return to the form several times before submitting the expression of interest. Please note that an organisation (a legal entity) may submit two expressions of interest, by themselves or in collaboration with others. If you haven't received an email, please check your junk folder.",
        dropZoneHeader: "Invitation to CSOs for Partnership with Sida",
        dropZoneSubHeader: "Please upload the document requested from Sida here. Select a document type and upload your file. Repeat this process for each document type. You can upload more than one file for each document type.",
        dropZoneSubHeader2: "Once files are uploaded, they will be automatically saved and registered in our system. You can however delete and re-upload files if needed. After you have finished uploading all your files, exit this site and return to your application.",
        dropZoneSubHeader3: "The document should be in .docx, .doc, .xls, .xlsx, .ppt, .pptx or .pdf format and not exceed 10 MB.",
        dropZoneMandatoryDocuments: "Mandatory document",
        dropZoneGDPRDocuments: "Uploaded documents will be encrypted and handled according to GDPR.",
        dropZoneAcceptedLanguages: "Only documents in Swedish or English will be accepted.",
        requiredDocuments: "You need to upload all mandatory documents.",
        allDocumentsHasBeenUploaded: "All mandatory documents have been uploaded!",
        registrationClosedHeader: "Registration Closed",
        registrationClosedSubHeader: "The application period is now closed.",
    },
    textFieldLabels: {
        orgName: "Organisation Name",
        orgNumber: "Registration Number of Organisation (Leave Blank if not Applicable)",
        category: "Category of CSO *",
        name: "Full Name of Contact Person",
        email: "Contact Email",
        phoneNumber: "Contact Phone Number (Including Country Code)",
    },
    information: {
        informationTextPart1: "In case of questions concerning the application process, please consult the ",
        informationTextPart2: " If you still have a question or should you require technical assistance with the form, please contact us here "
    }
};
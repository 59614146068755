export enum ApplicationStatus {
    PENDING = 0,
    APPROVED = 1,
    ALREADYEXISTS = 2,
    MAXIMUMCATEGORIES = 3,
    FAILED = 4
}

export enum CategoryKeys {
    CATEGORY1 = 'category1',
    CATEGORY2 = 'category2',
    CATEGORY3 = 'category3',
    CATEGORY4 = 'category4',
    CATEGORY5 = 'category5',
}

export enum UploadStatus {
    NotStarted = 0,
    Uploading = 1,
    Finished = 2,
    Error = 3,
    Updating = 4
}
import React from "react";
import { FormComponent } from "../components/form/FormComponent";
import { FormClosedComponent } from "../components/form/FormClosedComponent";
import { FORM_IS_OPEN } from "../Constants";


export const Form = () => {
  const formIsOpen = FORM_IS_OPEN === "false" ? false : true;
    return (
      formIsOpen ? <FormComponent /> : <FormClosedComponent />
    );
};
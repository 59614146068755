import './App.css';
import React from 'react';
import { Form } from './pages/Form';
import { DropZone } from './pages/DropZone';
import { Route, Routes } from 'react-router-dom';

function App(): JSX.Element {
  return (
    <Routes>
      <Route path="/uploadadmin/:id" element={<DropZone />} />
      <Route path="*" element={<Form />} />
    </Routes>
  );
}

export default App;

import React, { useEffect, useRef, useState } from 'react';
import './FormComponent.scss';
import ReCAPTCHA from 'react-google-recaptcha'
import { Avatar, Box, Button, CircularProgress, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material';
import { Done, Notifications } from '@mui/icons-material';
import { IFormInput, IFormValuesValidation } from '../../interfaces/interface';
import { submitForm } from '../../services/FormService';
import { ApplicationStatus } from '../../constants/constants';
import { SITE_KEY } from '../../Constants';
import { registrationStrings as strings } from '../../constants/texts';

export const FormComponent = (): JSX.Element => {

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<IFormInput>({ email: '', name: '', orgName: '', orgNumber: '', phoneNumber: '', token: ''});
  const [formValuesValidation, setFormValuesValidation] = useState<IFormValuesValidation>({} as IFormValuesValidation);
  const [recaptchaIsValid, setRecaptchaIsValid] = useState<boolean>(false);
  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [applicationStatus, setApplicationStatus] = useState<ApplicationStatus>(ApplicationStatus.PENDING);

  const recaptcha = useRef<ReCAPTCHA | null>(null);
  const sidalogo = require('../../images/Sida_logo_color_text_ENG-2.png');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formIsValid) {
      setIsLoading(true);
      const result = await submitForm(formValues);
      setApplicationStatus(result);
      setIsLoading(false);
    } else {
      setApplicationStatus(ApplicationStatus.FAILED);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    switch (event.target.name) {
      case 'hjndfklfdksk_orgNumber':
        setFormValues(prevValues => ({ ...prevValues, orgNumber: value }));
        break;
      case 'hjndfklfdksk_orgName':
        setFormValues(prevValues => ({ ...prevValues, orgName: value }));
        break;
      case 'hjndfklfdksk_email':
        setFormValues(prevValues => ({ ...prevValues, email: value }));
        break;
      case 'hjndfklfdksk_phoneNumber':
        setFormValues(prevValues => ({ ...prevValues, phoneNumber: value }));
        break;
      case 'hjndfklfdksk_name':
        setFormValues(prevValues => ({ ...prevValues, name: value }));
        break;
      default:
        break;
    }
  };

  const handleValidation = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;

    switch (event.target.name) {
      case 'hjndfklfdksk_orgName':
        if (value?.trim().length > 0) {
          setFormValuesValidation(prevValidation => ({ ...prevValidation, orgNameErrorText: '' }));
        } else {
          setFormValuesValidation(prevValidation => ({ ...prevValidation, orgNameErrorText: strings.error.orgName }));
        }
        break;
      case 'hjndfklfdksk_email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(value)) {
          setFormValuesValidation(prevValidation => ({ ...prevValidation, emailErrorText: '' }));
        } else {
          setFormValuesValidation(prevValidation => ({ ...prevValidation, emailErrorText: strings.error.email }));
        }
        break;
      case 'hjndfklfdksk_phoneNumber':
        if (value?.trim().length > 0) {
          setFormValuesValidation(prevValidation => ({ ...prevValidation, phoneErrorText: '' }));
        } else {
          setFormValuesValidation(prevValidation => ({ ...prevValidation, phoneErrorText: strings.error.phoneNumber }));
        }
        break;
      case 'hjndfklfdksk_name':
        if (value?.trim().length > 0) {
          setFormValuesValidation(prevValidation => ({ ...prevValidation, nameErrorText: '' }));
        } else {
          setFormValuesValidation(prevValidation => ({ ...prevValidation, nameErrorText: strings.error.name }));
        }
        break;
      default:
        break;
    }
  };

  const handleRecaptcha = () => {
    const captchaValue = recaptcha?.current?.getValue();
    if (!captchaValue) {
      setFormValues(prevValues => ({ ...prevValues, token: ''}));
      setRecaptchaIsValid(false);
    } else {
      setFormValues(prevValues => ({ ...prevValues, token: captchaValue}));
      setRecaptchaIsValid(true);
    }
  };

  const validateForm = () => {
    if (formValuesValidation.emailErrorText === '' && 
        formValuesValidation.phoneErrorText === '' && 
        formValuesValidation.orgNameErrorText === '' && 
        formValuesValidation.nameErrorText === '' && 
        recaptchaIsValid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  };

  useEffect(() => {
    validateForm();
  }, [recaptchaIsValid, formValuesValidation]);

  return (
      <Container component="main" className="form-container">
        <CssBaseline />
        {isLoading ?
          <Box sx={{ 
              mt: 14,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              }}>
            <CircularProgress size={48} />
          </Box>
          : applicationStatus === ApplicationStatus.APPROVED ?
            <Box className="status-box">
              <Avatar sx={{ m: 1, bgcolor: 'success.main' }}>
                <Done />
              </Avatar>
              <Typography component="h1" variant="h5" sx={{ mt: 2, textAlign: 'center' }}>
              {strings.applicationStatus.approvedHeader}
              </Typography>
              <Typography component="h5" variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
               {strings.applicationStatus.approvedSubHeader}
              </Typography>
              <Typography component="h5" variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
               {strings.applicationStatus.approvedDecription}
              </Typography>
              <Typography component="h5" variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
               {strings.applicationStatus.approvedDescription2}
              </Typography>
            </Box>
            : applicationStatus === ApplicationStatus.ALREADYEXISTS ?
              <Box className="status-box">
                <Avatar sx={{ m: 1, bgcolor: 'warning.light' }}>
                  <Notifications />
                </Avatar>
                <Typography component="h1" variant="h5" sx={{ mt: 2, textAlign: 'center' }}>
                  {strings.applicationStatus.alreadyExistsHeader}
                </Typography>
                <Typography component="h5" variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
                  {strings.applicationStatus.alreadeExistsSubheader}
                </Typography>
                <Button
                  onClick={() => window.location.reload()}
                  variant="contained"
                  sx={{ mt: 7, mb: 2}}
                >
                  {strings.global.goBackButton}
                </Button>
              </Box>
              : applicationStatus === ApplicationStatus.MAXIMUMCATEGORIES ?
              <Box className="status-box">
              <Avatar sx={{ m: 1, bgcolor: 'warning.light' }}>
                <Notifications />
              </Avatar>
              <Typography component="h1" variant="h5" sx={{ mt: 2, textAlign: 'center' }}>
                {strings.applicationStatus.maximumApplicationsHeader}
              </Typography>
              <Typography component="h5" variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
                {strings.applicationStatus.maximumApplicationsSubheader}
              </Typography>
              <Button
                onClick={() => window.location.reload()}
                variant="contained"
                sx={{ mt: 7, mb: 2}}
              >
                {strings.global.goBackButton}
              </Button>
            </Box>
              : applicationStatus === ApplicationStatus.FAILED ?
                <Box className="status-box">
                  <Avatar sx={{ m: 1, bgcolor: 'error.main' }}>
                    !
                  </Avatar>
                  <Typography component="h1" variant="h5" sx={{ mt: 2, textAlign: 'center' }}>
                    {strings.applicationStatus.failedHeader}
                  </Typography>
                  <Typography component="h5" variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
                    {strings.applicationStatus.failedSubHeader}
                  </Typography>
                  <Button
                      onClick={() => window.location.reload()}
                      variant="contained"
                      sx={{ mt: 7, mb: 2}}
                    >
                      {strings.global.goBackButton}
                    </Button>
                </Box>
                :
                <Box className="intro-box">
                  <img src={sidalogo} alt="Sida logo" className="sida-logo"/>
                  <Typography component="h1" variant="h5">
                    {strings.global.registrationHeader}
                  </Typography>
                  <Typography component="h5" variant="body1" sx={{ mt: 3, textAlign: 'center' }}>
                    {strings.global.registrationSubHeader}
                  </Typography>
                  <Box className="wrapper-box" component="form" onSubmit={handleSubmit} sx={{ mt: 5 }} autoComplete='off'>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          name="hjndfklfdksk_orgName"
                          required
                          fullWidth
                          autoComplete='new-password'
                          type='text'
                          label={strings.textFieldLabels.orgName}
                          value={formValues.orgName}
                          onChange={handleInputChange}
                          onBlur={handleValidation}
                          error={formValuesValidation.orgNameErrorText?.length > 0}
                          helperText={formValuesValidation.orgNameErrorText}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          autoComplete='new-password'
                          type='text'
                          label={strings.textFieldLabels.orgNumber}
                          name="hjndfklfdksk_orgNumber"
                          value={formValues.orgNumber}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <div className='form-divider'></div>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="hjndfklfdksk_name"
                          label={strings.textFieldLabels.name}
                          autoComplete='new-password'
                          type='text'
                          value={formValues.name}
                          onChange={handleInputChange}
                          onBlur={handleValidation}
                          error={formValuesValidation.nameErrorText?.length > 0}
                          helperText={formValuesValidation.nameErrorText}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          label={strings.textFieldLabels.email}
                          name="hjndfklfdksk_email"
                          autoComplete='new-password'
                          type='text'
                          value={formValues.email}
                          onChange={handleInputChange}
                          onBlur={handleValidation}
                          error={formValuesValidation.emailErrorText?.length > 0}
                          helperText={formValuesValidation.emailErrorText}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="hjndfklfdksk_phoneNumber"
                          label={strings.textFieldLabels.phoneNumber}
                          autoComplete='new-password'
                          type='text'
                          value={formValues.phoneNumber}
                          onChange={handleInputChange}
                          onBlur={handleValidation}
                          error={formValuesValidation.phoneErrorText?.length > 0}
                          helperText={formValuesValidation.phoneErrorText}
                        />
                      </Grid>
                      <Grid item xs={12} className="recaptcha-wrapper">
                        <ReCAPTCHA onChange={handleRecaptcha} ref={recaptcha} sitekey={SITE_KEY} />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      disabled={!formIsValid}
                    >
                      {strings.global.submitButton}
                    </Button>
                  </Box>
                  <Box className="wrapper-box">
                  <Typography component="h5" variant="body1" sx={{mb: 8, textAlign: 'center' }}>
                    {strings.information.informationTextPart1}
                      <a href="https://www.sida.se/en/for-partners" target="_blank" rel="noopener noreferrer">FAQ</a>.
                    {strings.information.informationTextPart2}
                      <br/>
                        <a href="mailto:invitation-sida-cso@sida.se">invitation-sida-cso@sida.se</a>.
                  </Typography>
                  </Box>
                </Box>
        }
      </Container>
  );
}
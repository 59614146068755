import React from 'react';
import './FormClosedComponent.scss';
import { Box, Container, Grid, Typography } from '@mui/material';
import { registrationStrings as strings } from '../../constants/texts';

export const FormClosedComponent = (): JSX.Element => {
    const sidalogo = require('../../images/Sida_logo_color_text_ENG-2.png');
    return (
        <Container component="main" className="form-container">
            <Box className="intro-box">
                <img src={sidalogo} alt="Sida logo" className="sida-logo" />
                <Typography component="h1" variant="h5">
                    {strings.global.registrationClosedHeader}
                </Typography>
                <Typography component="h5" variant="body1" sx={{ mt: 3, mb: 4, textAlign: 'center' }}>
                    {strings.global.registrationClosedSubHeader}
                </Typography>
            </Box>
        </Container>
    );
};
import React, { useEffect, useState } from "react";
import { DropZoneComponent } from "../components/dropzone/DropZoneComponent";
import { FormClosedComponent } from "../components/form/FormClosedComponent";
import { useParams } from 'react-router-dom';
import { validateToken } from "../services/TokenService";
import { Box, CircularProgress } from "@mui/material";


export const DropZone = () => {
    const [tokenIsValid, setTokenIsValid] = useState<boolean | null>(null);
    const [tokenIsLoading, setTokenIsLoading] = useState<boolean>(true);

    const { id } = useParams();
    
    const verifyToken = async () => {
      if (id) {
        const result = await validateToken(id);
        setTokenIsValid(result);
        setTokenIsLoading(false);
      }
    };

    useEffect(() => {
       verifyToken();
    }, []);

    return (
      tokenIsLoading ? 
        <Box sx={{ 
            mt: 48,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            }}>
          <CircularProgress size={48} />
        </Box>
      // :
      //   tokenIsValid ? 
      //   <DropZoneComponent /> 
        : <FormClosedComponent />
    );
};